<template>
  <validation-observer ref="simpleRules">
    <b-card-code :title="$route.name !== 'edit-kejadian' ? 'Form Tambah Kejadian' : 'Form Edit Kejadian'">
      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Tujuan"
            rules="required"
          >
            <b-form-group
              label="Tujuan"
              label-for="Tujuan"
            >
              <b-form-input
                id="Tujuan"
                v-model="Tujuan"
                placeholder="Tulis Tujuan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Institusi"
            rules="required"
            class="mb-1"
          >
            <b-form-group
              label="Institusi"
              label-for="Institusi"
            >
              <b-form-input
                id="Institusi"
                v-model="Institusi"
                placeholder="Tulis Institusi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
            class="mb-2"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <b-form-select
                v-model="Kategori"
                :options="optionsKategori"
                placeholder="as"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Alamat"
            rules="required"
            class="mb-1"
          >
            <b-form-group
              label="Alamat"
              label-for="Alamat"
            >
              <b-form-input
                id="Alamat"
                v-model="Alamat"
                placeholder="Tulis Alamat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-row
            v-if="!gantiFile && $route.name === 'edit-kejadian'"
            class="match-height"
          >
            <h5 class="titlerow">
              File Kejadian
            </h5>
            <b-col
              v-for="item in file"
              :key="item.access_url"
              md="12"
              class="fileSurat"
            >
              <div
                class="open-file"
                @click="openFile(item.access_url)"
              >
                <feather-icon
                  icon="FileIcon"
                  size="56"
                />
                <h5 class="ml-1">
                  Open File
                  <span> {{ item.real_filename }}{{ item.ext }} </span>
                </h5>
              </div>
            </b-col>

            <b-button
              v-show="$route.name === 'edit-kejadian'"
              v-model="gantiFile"
              variant="outline-primary"
              class="bg-gradient-primary mt-1 ml-1 "
              style="height: 29px"
              size="sm"
              @click.prevent="toggleFile"
            >
              <span class="align-middle">Ganti File</span>
            </b-button>
          </b-row>

          <b-row
            v-else
            class="mb-0"
          >
            <b-col>
              <b-form-group
                label="File Kejadian"
                label-for="fileKejadian"
              >
                <!-- Styled -->
                <b-form-file
                  id="FileSurat"
                  ref="file"
                  v-model="file"
                  type="file"
                  placeholder="Tambah File Kejadian"
                  drop-placeholder="Drop file here..."
                  multiple="multiple"
                  @change="fileChange"
                />

                <b-button
                  v-show="$route.name === 'edit-kejadian'"
                  v-model="gantiFile"
                  variant="outline-primary"
                  class="bg-gradient-primary mt-1"
                  size="sm"
                  @click.prevent="toggleFile"
                >
                  <span class="align-middle">Open File</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <validation-provider
            #default="{ errors }"
            name="Email"
            class="mt-1"
          >
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <b-form-input
                id="Email"
                v-model="email"
                placeholder="Tulis Email"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Nomor Telp."
            class=" mt-1"
          >
            <b-form-group
              label="Nomor Telp."
              label-for="Nomor Telp."
            >
              <b-form-input
                id="Nomor Telp."
                v-model="Phone"
                placeholder="Tulis Nomor Telp."
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Tag"
            rules="required"
            class="mt-1 mb-1"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>

        <b-col md="6">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Jam Masuk"
                rules="required"
              >
                <b-form-group
                  label="Jam Masuk"
                  label-for="Jam Masuk"
                >
                  <flat-pickr
                    v-model="InTime"
                    class="form-control"
                    placeholder="Masukkan Jam Masuk"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Jam Keluar"
              >
                <b-form-group
                  label="Jam Keluar"
                  label-for="Jam Keluar"
                >
                  <flat-pickr
                    v-model="OutTime"
                    placeholder="Masukkan Jam Keluar"
                    class="form-control"
                    :disabled="$route.name !== 'edit-kejadian'"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-for="(row, index) in Pengunjung"
            :key="index"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Pengunjung {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deletePengunjung(index)"
            > Hapus Pengunjung </span>
            <b-col md="6">
              <b-form-group
                label="Nama Pengunjung"
                label-for="Nama Pengunjung"
                class="mb-1"
              >
                <b-form-input
                  id="Nama Pengunjung"
                  v-model="row.name"
                  placeholder="Input Nama Pengunjung"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Pengunjung"
                label-for="Nomor Pengunjung"
                class="mb-1"
              >
                <b-form-input
                  id="Nomor Pengunjung"
                  v-model="row.visitor_number"
                  placeholder="Input Nomor Pengunjung"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Telfon"
                label-for="Warna Kendaraan"
              >
                <b-form-input
                  id="Kendaraan"
                  v-model="row.phone"
                  placeholder="Input Warna Kendaraan"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="Jenis Kelamin"
              >
                <b-form-select
                  v-model="row.gender"
                  placeholder="Pilih Tipe Kendaraan"
                  :options="optionGender"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="outline"
            class="bg-gradient mb-2 bt-add"
            type="submit"
            size="sm"
            @click.prevent="addPengunjung"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Pengunjung</span>
          </b-button>

          <b-row
            v-for="(row, index) in Kendaraan"
            :key="index"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Kendaraan {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deleteKendaraan(index)"
            > Hapus Kendaraan </span>
            <b-col md="6">
              <b-form-group
                label="Tipe Kendaraan"
                label-for="Tipe Kendaraan"
                class="mb-1"
              >
                <b-form-select
                  v-model="row.vehicle_type"
                  placeholder="Masukkan Tipe Kendaraan"
                  :options="optionKendaraan"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Brand Kendaraan"
                label-for="Brand Kendaraan"
                class="mb-1"
              >
                <b-form-input
                  id="Kendaraan"
                  v-model="row.brand"
                  placeholder="Input Brand"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Warna Kendaraan"
                label-for="Warna Kendaraan"
              >
                <b-form-input
                  id="Kendaraan"
                  v-model="row.color"
                  placeholder="Input Warna Kendaraan"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Kendaraan"
                label-for="Nomor Kendaraan"
              >
                <b-form-input
                  id="Kendaraan"
                  v-model="row.police_number"
                  placeholder="Input Nomor Kendaraan"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="outline"
            class="bg-gradient bt-add"
            type="submit"
            size="sm"
            @click.prevent="addKendaraan"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Kendaraan</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
import dayjs from 'dayjs'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    flatPickr,
  },
  data() {
    return {
      Tujuan: '',
      Pengunjung: [
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ],
      Kendaraan: [
        {
          vehicle_type: '',
          brand: '',
          brand_type: '',
          police_number: '',
          color: '',
        },
      ],
      InTime: null,
      OutTime: null,
      Institusi: '',
      Kategori: '',
      Phone: '',
      Alamat: '',
      today: null,
      file: [],
      // files: [],
      gantiFile: false,
      privates: false,
      value: [],
      tags: [],
      email: '',
      dir: 'ltr',
      errorLog: '',
      optionsKategori: [
        { value: '', text: 'Pilih Kategori' },
        { value: 'Undangan', text: 'Undangan' },
        { value: 'Permohonan', text: 'Permohonan' },
        { value: 'CSR', text: 'CSR' },
        { value: 'Instansi Pendidikan', text: 'Instansi Pendidikan' },
        { value: 'Pemberitahuan', text: 'Pemberitahuan' },
        { value: 'Lain-lain', text: 'Lain-lain' },
      ],
      optionGender: [
        { value: '', text: 'Pilih Jenis Kelamin' },
        { value: 'M', text: 'Pria' },
        { value: 'F', text: 'Wanita' },
      ],
      optionKendaraan: [
        { value: '', text: 'Pilih Tipe Kendaraan' },
        { value: 'Roda 2', text: 'Roda 2' },
        { value: 'Roda 4', text: 'Roda 4' },
        { value: 'Truk', text: 'Truk' },
      ],
      selected: [],
    }
  },
  watch: {
    $route() {
      if (this.$route.name !== 'edit-kejadian') {
        this.clearData()
      }
    },
  },

  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.today = dayjs(new Date()).$d
    if (this.$route.name === 'edit-kejadian') {
      this.getKejadian()
    }
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'GuestBook',
        },
      })
      this.file = data.data.map(val => ({
        real_filename: val.real_filename,
        new_filename: val.new_filename,
        ext: val.ext,
        date: val.date,
      }))
    },

    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addDispo()
        }
      })
    },

    clearData() {
      this.Kategori = ''
      this.Phone = ''
      this.email = ''
      this.Tujuan = ''
      this.Institusi = ''
      this.Alamat = ''
      this.InTime = null
      this.OutTime = null
      this.file = []
      this.Kendaraan = [
        {
          vehicle_type: '',
          brand: '',
          brand_type: '',
          police_number: '',
          color: '',
        },
      ]
      this.Pengunjung = [
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ]
      this.tags = []
    },

    async getKejadian() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/guest-book/detail/${param}`)
      this.Kategori = data.category.name
      this.Phone = data.phone
      this.email = data.email
      this.Tujuan = data.purpose
      this.Institusi = data.institution
      this.Alamat = data.address
      this.InTime = dayjs(data.check_in_date).format('HH:mm')
      this.OutTime = dayjs(data.check_out_date).format('HH:mm')
      this.file = data.file
      this.Kendaraan = data.vehicle.map(e => ({
        vehicle_type: e.vehicle_type,
        brand: e.brand,
        brand_type: e.brand_type,
        police_number: e.police_number,
        color: e.color,
      }))
      this.Pengunjung = data.visitor
      this.tags = data.tag.map(e => e.name)
    },

    async addDispo() {
      let url = ''
      const param = this.$route.params.pathMatch
      if (this.$route.name !== 'edit-kejadian') {
        url = 'siap/guest-book/create'
      } else {
        url = `siap/guest-book/update/${param}`
      }
      await axios
        .post(`${url}`, {
          cat: this.Kategori,
          phone: this.Phone,
          email: this.email,
          police_number: '',
          institution: this.Institusi,
          purpose: this.Tujuan,
          address: this.Alamat,
          check_in_date: dayjs(this.today).format(
            `YYYY-MM-DDT${this.InTime}:ss+07:00`,
          ),
          check_out_date:
            this.OutTime === null
              ? null
              : dayjs(this.today).format(`YYYY-MM-DDT${this.OutTime}:ss+07:00`),
          vehicle: this.Kendaraan,
          visitor: this.Pengunjung,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // this.$router.push({ name: `detail-disposisi/${response.data.data.id}` })
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
    openFile(e) {
      window.open(e, '_blank')
    },
    addPengunjung() {
      this.Pengunjung = [
        ...this.Pengunjung,
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ]
    },

    addKendaraan() {
      this.Kendaraan = [
        ...this.Kendaraan,
        {
          vehicle_type: '',
          brand: '',
          brand_type: '',
          police_number: '',
          color: '',
        },
      ]
    },

    deletePengunjung(index) {
      const current = this.Pengunjung.slice()
      current.splice(index, 1)
      this.Pengunjung = current
    },

    deleteKendaraan(index) {
      const current = this.Kendaraan.slice()
      current.splice(index, 1)
      this.Kendaraan = current
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.warning2 {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: -1px;
  right: 15px;
  cursor: pointer;
}
.titlerow {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}

.bt-add {
  background: #fdf6eb;
}
</style>
